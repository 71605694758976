import React from "react";

const TermsAndConditionContents = () => {
    return (
        <div className="front-page"><section className="breadcrumb-section undefined"><nav aria-label="breadcrumb"><ol className="breadcrumb"><div className="d-flex container"><li className="breadcrumb-item"><a href="/"><span>Home</span></a></li><li className="breadcrumb-item active">Terms &amp; Conditions</li></div></ol></nav></section><section className="title-description"><div className="container"><div className="text-left"><h2 className="mb-4">Terms Of Use</h2><div><p>WDN aims at making a connection between employers and job seekers. We designed our services to make the worker-finding process simple and effective. We provide Companies; the freedom to choose Candidates based on their experience. We provide Candidates the freedom to choose their preferred job, work hours, and much more.</p>
            <p>We do not charge you to create an account on WDN. However, Companies need to pay a certain amount to see Candidates’ contact information.</p>
            <p>You can find how we collect and use your information in our&nbsp;<a href="https://www.wdnexus.com/privacy"><strong>privacy policy&nbsp;</strong></a>. Furthermore, here are the terms of use you need to follow while using our product. Violation of any terms is subjected to the deactivation of your account.</p>
            <ol>
                <li><strong>Who can use WDN? </strong><br/>
                    WDN has three types of users: Candidates who are in search of a job; Companies looking for experienced workers/ freshers; and visitors.</li>
                <li>To apply for a job, the Candidate needs to be a minimum of 16 years old. We do not verify your account if your age is below sixteen.</li>
                <li>The purchase price may vary following foreign exchange fees or your location of work. For example, the purchase price for people living in Nepal and for ones living in America varies.The purchase prices are also determined by the experience and industry of the Candidate. Both the Companies and Candidates are associated with an industry. Companies’ demands vary accordingly. Thus the purchase price varies accordingly (higher purchase price for the high demanding Companies and low purchase price for less demanding Companies).</li>
                <li>Users cannot create an account on behalf of someone else. Both the Companies and Candidates have to use their official names while creating an account. If we find a fake account via any means, we can suspend it without any prior notice.</li>
                <li>Once a Company purchases a Candidate’s CV, the validity period is fourteen days from the day of purchase. You are not allowed to share the CV with other Companies. We expect your respect for our confidentiality.</li>
                <li>While you upload a profile picture, it should be official and acceptable. Any account with a sensitive photo is unacceptable.</li>
                <li>While you update your details, they should be accurate. With accurate data, we can find appropriate jobs for Candidates and suitable Candidates for Companies.</li>
                <li>We may change, suspend, or discontinue any of our services without prior information. We may also modify our prices.</li>
                <li>We send you notices and messages in the following ways:
                    <ul>
                        <li>We can send you messages within the service</li>
                        <li>To the contact information, you provided to us as email, phone number, or physical address.</li>
                    </ul>
                    <p>To contact you at a proper address, you need to keep your details up to date.</p></li>
                <li>WDN has the authority and can change the privacy policies and terms of use from time to time. We continuously work to improve our product and services. In the process, we may need to update these terms. If you continue to use our product, you would be bound by the new terms and policies.</li>
            </ol>
            <p>These terms form an agreement between you and our Company. We are open to your feedback and suggestions.</p>
            <p>By using our product, you agree to our terms and conditions.</p>
        </div></div></div></section></div>
    )
}
export default TermsAndConditionContents;