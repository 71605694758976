import React from "react";
import TermsAndConditionContents from "../../pages-sections/termsAndConditionContents";
import companyMetaImage from "../../images/tac_meta.jpg";
import NewPageLayout from "../../components/layout/new-page-layout";

const TAC = () => {
    return (
        <NewPageLayout
            isCandidate={false}
            title="Terms & Conditions - WDN"
            description="WDN makes the process of finding workers simple and effective. "
            seoImage={companyMetaImage}
        >
            <TermsAndConditionContents />
        </NewPageLayout>
    )
}
export default TAC; 